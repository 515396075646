import styled from 'styled-components'
import { TextField } from '@quotatisgroup/uikit-public.ui.text-field'
import { Select } from '@quotatisgroup/uikit-public.ui.select'
import { DatePicker } from '@quotatisgroup/uikit-public.ui.date-picker'
import { Card } from '@quotatisgroup/uikit-public.ui.card'
import { GREY_COLOR } from '@/constants/colors'

export const LabelWithIcon = styled.label`
  display: flex;
  align-items: center;
  color: ${GREY_COLOR};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`

export const StyledTextField = styled(TextField)`
  margin: 0;
`

export const StyledSelectField = styled(Select)`
  margin: 0;

  // Always show the scrollbar
  div.options.open::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  div.options.open::-webkit-scrollbar:vertical {
    width: 11px;
  }

  div.options.open::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  div.options.open::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }

  div.options.open::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
`

export const StyledDateField = styled(DatePicker)`
  margin: 0;
`

export const StyledCard = styled(Card)`
  overflow: visible;
`
