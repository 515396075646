import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translations from '../fr.json'

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}

async function returnsPromise() {
  return i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fr: {
        translation: translations
      }
    },
    returnNull: false,
  })
}
returnsPromise().then(
  () => {},
  () => {},
)

export default i18n

/*
  HOW USE i18n :

  // you need to import useTranslation in your file :
  import { useTranslation } from 'react-i18next'

  // create de const (you can NOT rename 't') :
  const { t } = useTranslation()

  return (
    <div>
      <p>
        // use your trad :
        {t('yourTranslationTerm')}
      </p>
    </div>
  )
*/
