import { useEffect, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { selectIdToken, selectAccessToken } from '@/store/auth/authSlice'
import config from '@/config'

const AxiosProvider = ({ children }: { children: ReactElement }) => {
  const idToken = useSelector(selectIdToken)
  const accessToken = useSelector(selectAccessToken)

  useEffect(() => {
    axios.interceptors.request.use(
      axiosConfig => {
        const newAxiosConfig = { ...axiosConfig }
        newAxiosConfig.baseURL = config.api.url

        if (newAxiosConfig.headers) {
          newAxiosConfig.headers['content-type'] = 'application/json'
          newAxiosConfig.headers.apiKey = config.api.apiKey
          if (accessToken) {
            newAxiosConfig.headers.Authorization = `Bearer ${accessToken}`
          }

          if (idToken) {
            newAxiosConfig.headers.Identification = `Bearer ${idToken}`
          }
        }

        return newAxiosConfig
      },
      error => Promise.reject(error),
    )
  }, [idToken, accessToken])

  return children
}

export default AxiosProvider
